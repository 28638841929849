<template lang="pug">
AppLayout
  .text-center.justify-center.fill-height.py-5
    v-btn(color="primary" @click="show = !show" :outlined="show") Show Dialog
  template(slot="dialogs")
    Hello(v-model="show")
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    Hello: () => import('./Hello.vue'),
    AppLayout: () => import('./Layout.vue'),
  },
  data: () => ({
    show: false,
  }),
}
</script>
<style lang="stylus">

</style>
